import SparkMD5 from 'spark-md5'
import fileDownload from 'js-file-download'
require('./Blob') // for shitty Safari (Blob.text)

export default {
  methods: {
    async saveUploadedFile (file, progressCallback) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const fileText = await file.text()
        const fileHash = SparkMD5.hash(fileText)
        const fileType = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
        const fileName = fileHash + '.' + fileType
        const filePath = this.getFilePath(fileName)
        // let snapshot = await this.$fb.fs.ref(filePath).put(file) // upload (add/replace)
        const uploadTask = this.$fb.fs.ref(filePath).put(file, { cacheControl: 'public,max-age=31536000' }) // upload (add/replace)
        uploadTask.on('state_changed',
          function (snapshot) {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            if (progressCallback) progressCallback(progress)
          },
          function (error) {
            reject(error)
          },
          function () { // success
            resolve(fileName)
          }
        )
      })
    },
    async getFileURL (fileName) {
      if (!fileName) return ''
      const filePath = this.getFilePath(fileName)
      return this.$fb.fs.ref(filePath).getDownloadURL()
    },
    getFilePath (fileName) {
      return 'contents/' + fileName
    },
    async downloadFile (file) {
      const url = await this.getFileURL(file.src)
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = async () => {
        const blob = xhr.response
        this.openBlob(file.name, blob)
      }
      xhr.open('GET', url)
      xhr.send()
    },
    async openBlob (fileName, blob) {
      if (this.$root.native) {          
        const { Filesystem, Directory } = await import('@capacitor/filesystem')
        const { FileOpener } = await import('@ionic-native/file-opener')
        const reader = new FileReader()
        reader.onload = async () => {
          const fileData = reader.result
          const mimeType = fileData.substr(5, fileData.indexOf(';') - 5) // "data:application\/pdf;base64,
          const fileResult = await Filesystem.writeFile({
            path: fileName,
            data: fileData,
            directory: Directory.Cache
          })
          if (fileResult && fileResult.uri) {
            FileOpener.open(fileResult.uri, mimeType)
          }
        }
        reader.readAsDataURL(blob)
      } else {
        fileDownload(blob, fileName)
      }
    },
    async openFile (fileName, fileData, mimeType) {
      const { Filesystem, Directory, Encoding } = await import('@capacitor/filesystem')
      const { FileOpener } = await import('@ionic-native/file-opener')
      const fileResult = await Filesystem.writeFile({
        path: fileName,
        data: fileData, // string
        directory: Directory.Cache,
        encoding: Encoding.UTF8
      })
      if (fileResult && fileResult.uri) {
        FileOpener.open(fileResult.uri, mimeType)
      }
    }
  }
}
